/*
 * Servizio che gestisce i Tag
*/

import { Tag, TagTenant } from "atfcore-commonclasses";
import { SenecaResponse } from "atfcore-commonclasses";

module TagManager {
    let app = angular.module("app");

    app.service("TagManager", function ($resource: ng.resource.IResourceService, GlobalApplicationData: any, $translate: angular.translate.ITranslateService, LibraryApplicationData: any, $q: any, $rootScope: any, SearchTagsService: any, toaster: any) {
        // Elenco con tutti i Tag disponibili
        this.tagList = {};
        this.tagList[LibraryApplicationData.constants.ARGUMENTS] = { list: null };
        this.tagList[LibraryApplicationData.constants.CLUSTERS] = { list: null };
        this.tagList[LibraryApplicationData.constants.FUNCTIONAL_AREAS] = { list: null };
        this.tagList[LibraryApplicationData.constants.LEVELS] = { list: null };
        this.tagList[LibraryApplicationData.constants.TYPE] = { list: null };
        this.tagList[LibraryApplicationData.constants.STATUS] = { list: null };
        this.tagList[LibraryApplicationData.constants.APPROACH] = { list: null };
        this.tagList[LibraryApplicationData.constants.TECH_COMPETENCES] = { list: null };
        this.tagList[LibraryApplicationData.constants.SOFT_COMPETENCES] = { list: null };
        this.tagList[LibraryApplicationData.constants.STREAMS] = { list: null };
        this.tagList[LibraryApplicationData.constants.LANG] = { list: null };
        this.tagList[LibraryApplicationData.constants.ADMIN_TAGS] = { list: null };

        // Promessa per la ricerca dei tag
        let searchTagPromise: any = null;

        // Metodo che ritorna l'intero tag
        this.getTag = (key: string): any => {
            let field: any = null;
            if (key && this.tagList[key]) {
                // Recupero il campo dalla mappa, clonandolo per non modificare l'originale
                field = JSON.parse(JSON.stringify(this.tagList[key]));
            }
            return field;
        };

        // Setta la classe CSS dei cluster
        this.setClusterClass = (clusterList: any[], currentFilter: any): any => {
            if (clusterList && clusterList.length) {
                for (let i = 0, clustersLength = clusterList.length; i < clustersLength; i++) {
                    // Setto il CSS del titolo
                    let currentResult = clusterList[i];
                    // Se c'è già un cluster selezionato, aggiungo un layer opaco per evitare la possibilità di selezionarne altri
                    if (currentFilter.atLeastOneSelected) {
                        currentResult.clusterDisabledCSS = "element-disabled opacity-layer";
                    }

                    if (currentResult && currentResult.tagAttributes && currentResult.tagAttributes.length) {
                        for (let z = 0, tagAttributesLength = currentResult.tagAttributes.length; z < tagAttributesLength; z++) {
                            let currentTagAttribute = currentResult.tagAttributes[z];
                            if (currentTagAttribute.attributeType === LibraryApplicationData.constants.COLOR) {
                                currentResult.clusterTitleCSS = "bg-" + currentTagAttribute.attributeValue;
                            }
                        }
                    }
                }
            }
        }

        // Aggiunge le etichette in traduzione
        this.addTagLabels = (tagList: any[], currentFilter: any): any => {
            // Etichette per i cluster
            if (currentFilter.code === LibraryApplicationData.constants.CLUSTERS) {
                currentFilter.tagsInTotalLabel = $translate.instant('librarySubFilters.CLUSTERS_TOTAL');

                if (currentFilter.selectedFilters && currentFilter.selectedFilters.length && currentFilter.selectedFilters.length === 1) {
                    currentFilter.tagsToSelectLabel = $translate.instant('librarySubFilters.CLUSTER_SELECTED');
                } else {
                    currentFilter.tagsToSelectLabel = $translate.instant('librarySubFilters.CLUSTERS_SELECTED');
                }
            }

            // Etichette per le aree funzionali
            if (currentFilter.code === LibraryApplicationData.constants.FUNCTIONAL_AREAS) {
                if (currentFilter.selectedFilters && currentFilter.selectedFilters.length && currentFilter.selectedFilters.length === 1) {
                    currentFilter.tagsToSelectLabel = $translate.instant('librarySubFilters.FUNCTIONAL_AREA_SELECTED');
                } else {
                    currentFilter.tagsToSelectLabel = $translate.instant('librarySubFilters.FUNCTIONAL_AREAS_SELECTED');
                }
            }

            // Etichette per i livelli
            if (currentFilter.code === LibraryApplicationData.constants.LEVELS) {
                if (tagList && tagList.length === 1) {
                    currentFilter.tagsInTotalLabel = $translate.instant('librarySubFilters.LEVEL_TOTAL');
                } else {
                    currentFilter.tagsInTotalLabel = $translate.instant('librarySubFilters.LEVELS_TOTAL');
                }
            }

            // Etichette per gli stream
            if (currentFilter.code === LibraryApplicationData.constants.STREAMS) {
                if (tagList && tagList.length === 1) {
                    currentFilter.tagsInTotalLabel = $translate.instant('librarySubFilters.STREAM_TOTAL');
                } else {
                    currentFilter.tagsInTotalLabel = $translate.instant('librarySubFilters.STREAMS_TOTAL');
                }
            }

            // Etichette per gli admin tags
            if (currentFilter.code === LibraryApplicationData.constants.ADMIN_TAGS) {
                if (tagList && tagList.length === 1) {
                    currentFilter.tagsInTotalLabel = $translate.instant('librarySubFilters.ADMIN_TAG_TOTAL');
                } else {
                    currentFilter.tagsInTotalLabel = $translate.instant('librarySubFilters.ADMIN_TAGS_TOTAL');
                }
            }

            // Etichette per le lingue
            if (currentFilter.code === LibraryApplicationData.constants.LANG) {
                if (tagList && tagList.length === 1) {
                    currentFilter.tagsInTotalLabel = $translate.instant('librarySubFilters.LANG_TOTAL');
                } else {
                    currentFilter.tagsInTotalLabel = $translate.instant('librarySubFilters.LANGS_TOTAL');
                }
            }
        }

        // Metodo che ritorna la lista degli elementi di un specifico tag
        this.getTagList = (key: string, currentFilter: any): void => {
            return $q((resolve: Function, reject: Function) => {
                if (key && currentFilter) {
                    if (key === LibraryApplicationData.constants.SOFT_AND_TECH_SKILLS) {
                            // Se sto cercando le competenze, devo fare chiamate multiple in quanto è la somma delle competenze tech e quelle soft
                            // Contenitore dei risultati
                            let mergedCompetenceListTmp: any = [];

                            // Contenitore delle promesse
                            let competencesPromises: Array<any> = [];

                            // Recupero le competenze tech e soft
                            for (let i = 0, competencesLength = LibraryApplicationData.constants.COMPETENCE_LIST.length; i < competencesLength; i++) {
                                let currentCompetence = LibraryApplicationData.constants.COMPETENCE_LIST[i];
                                competencesPromises.push(this.searchTags(currentCompetence, null, currentFilter).then((foundedCompetences: any) => {
                                    if(currentCompetence === LibraryApplicationData.constants.TECH_COMPETENCES){
                                        this.tagList[LibraryApplicationData.constants.TECH_COMPETENCES].list = foundedCompetences;
                                    }
                                    if(currentCompetence === LibraryApplicationData.constants.SOFT_COMPETENCES){
                                        this.tagList[LibraryApplicationData.constants.SOFT_COMPETENCES].list = foundedCompetences;
                                    }

                                    if (!mergedCompetenceListTmp.length) {
                                        mergedCompetenceListTmp = foundedCompetences;
                                    } else {
                                        mergedCompetenceListTmp = mergedCompetenceListTmp.concat(foundedCompetences);
                                    }
                                }));
                            }
                            $q.all(competencesPromises).then(() => {
                                // Ordino le competenze per titolo
                                if (mergedCompetenceListTmp) {
                                    let resultTmp = JSON.parse(JSON.stringify(mergedCompetenceListTmp));
                                    currentFilter.list.length = 0;

                                    // Li ordino per titolo
                                    this.sortByParameter(resultTmp, "title");

                                    // Rimuovo le competenze tech già selezionate
                                    this.removeAlreadySelected(LibraryApplicationData.constants.TECH_COMPETENCES, resultTmp, currentFilter.selectedTechSkill);
                                    
                                    // Rimuovo le competenze soft già selezionate
                                    this.removeAlreadySelected(LibraryApplicationData.constants.SOFT_COMPETENCES, resultTmp, currentFilter.selectedSoftSkill);

                                    // Li suddivido in lettere
                                    this.divideIntoLetters(resultTmp, currentFilter.lettersDivisionContainer, currentFilter);

                                    // Salvo il risultato
                                    if (resultTmp && resultTmp.length) {
                                        for (let i = 0, dataLength = resultTmp.length; i < dataLength; i++) {
                                            currentFilter.list.push(resultTmp[i]);
                                        }
                                    }

                                    resolve();
                                }
                            });
                    } else {
                        let tagListCall = this.searchTags(key, null, currentFilter);
                        tagListCall.then((result: any) => {
                            
                            this.tagList[key].list = result;

                            let resultTmp = JSON.parse(JSON.stringify(result));
                            currentFilter.list.length = 0;

                            // Se è un filtro per area funzionale, cluster, streams, lingue, tag liberi o livelli tolgo i tag già selezionati
                            if (key === LibraryApplicationData.constants.FUNCTIONAL_AREAS || key === LibraryApplicationData.constants.LANG || key === LibraryApplicationData.constants.CLUSTERS || key === LibraryApplicationData.constants.LEVELS || key === LibraryApplicationData.constants.STREAMS || key === LibraryApplicationData.constants.ADMIN_TAGS) {
                                this.removeAlreadySelected(key, resultTmp, currentFilter.selectedFilters);
                            }

                            // Se il filtro è per categoria, o area funzionale divido il risultato in lettere
                            if (key === LibraryApplicationData.constants.ARGUMENTS || key === LibraryApplicationData.constants.FUNCTIONAL_AREAS) {
                                if (!currentFilter.selectedFilters.length) {
                                   // this.tagList[LibraryApplicationData.constants.FUNCTIONAL_AREAS].list = result;
                                }
                                this.divideIntoLetters(resultTmp, LibraryApplicationData.constants.LETTERS_DIVISION, currentFilter);
                            }

                            // Se è un Cluster o un livello, verifico se ha un ordinamento
                            if (key === LibraryApplicationData.constants.CLUSTERS || key === LibraryApplicationData.constants.LEVELS || key === LibraryApplicationData.constants.FUNCTIONAL_AREAS) {
                                this.sortResult(resultTmp);
                            };

                            // Se è un cluster, setto le apposite classi
                            if (key === LibraryApplicationData.constants.CLUSTERS) {
                                this.setClusterClass(resultTmp, currentFilter);
                            }

                            // Se è uno stato oppure un approccio, devo aggiungere una specifica classe CSS qualora siano già selezionati
                            if (key === LibraryApplicationData.constants.STATUS) {
                                if (currentFilter.status.id !== 1) {
                                    for (let i = 0, resultLength = resultTmp.length; i < resultLength; i++) {
                                        let currentResult = resultTmp[i];
                                        if (currentResult.id === currentFilter.status.id) {
                                            currentResult.boxAlreadySelectedClass = 'filter-active';
                                            break;
                                        }
                                    }
                                }
                            }
                            else if (key === LibraryApplicationData.constants.APPROACH) {
                                if (currentFilter.competence.id !== 1) {
                                    for (let i = 0, resultLength = resultTmp.length; i < resultLength; i++) {
                                        let currentResult = resultTmp[i];
                                        if (currentResult.id === currentFilter.competence.id) {
                                            currentResult.boxAlreadySelectedClass = 'filter-active';
                                            break;
                                        }
                                    }
                                }
                            } else if (key === LibraryApplicationData.constants.TYPE) {
                                // Se è un filtro per tipologia, aggiungo una classe CSS per quelli già selezionati; fatto questo, aggiungo la loro etichetta in traduzione e l'icona appropriata
                                for (let i = 0, resultLength = resultTmp.length; i < resultLength; i++) {
                                    if (this.isFilterTypeSelected(resultTmp[i], currentFilter.selectedFilters)) {
                                        resultTmp[i].typeAlreadySelectedClass = 'filter-active';
                                    }
                                }
                                this.setIconAndLabelToFilterType(resultTmp);
                            }

                            // Traduco le varie etichette (ad esempio quella di "tag in totale")
                            this.addTagLabels(resultTmp, currentFilter);

                            // Salvo il risultato
                            if (resultTmp && resultTmp.length) {
                                for (let i = 0, dataLength = resultTmp.length; i < dataLength; i++) {
                                    currentFilter.list.push(resultTmp[i]);
                                }
                            }
                            resolve();
                        });
                    }
                } else {
                    reject();
                }
            });
        };

        // Metodo che ritorna tutti i tag
        this.getAllTags = (): Tag[] => {
            return this.tagList;
        };

        // Ordina in base un parametro
        this.sortByParameter = (tagList: any[], parameter: string): void => {
            // Se il primo elemento dell'Array è un Array, significa che ho già diviso in lettere e ho già ordinato
            if (tagList && tagList.length && parameter && parameter.length && !Array.isArray(tagList[0])) {
                tagList.sort((a: any, b: any) => {
                    if (a[parameter] > b[parameter]) {
                        return 1;
                    }
                    else if (a[parameter] < b[parameter]) {
                        return -1;
                    }
                    return 0;
                });
            }
        }

        // Imposta l'etichetta in traduzione e l'icona ai filtri per "tipologia"
        this.setIconAndLabelToFilterType = (filterList: any[]) => {
            if (filterList && filterList.length) {
                for (let i = 0, filterLength = filterList.length; i < filterLength; i++) {
                    let currentFilter = filterList[i];
                    if (currentFilter.code == LibraryApplicationData.constants.MAGAZINE) {
                        currentFilter.icon = "import-contacts"
                        currentFilter.subIconType = $translate.instant('card.types.MAGAZINE');
                    } else if (currentFilter.code == LibraryApplicationData.constants.DVD) {
                        currentFilter.icon = "album"
                        currentFilter.subIconType = $translate.instant('card.types.DVD');
                    } else if (currentFilter.code == LibraryApplicationData.constants.BOOK) {
                        currentFilter.icon = "library-books"
                        currentFilter.subIconType = $translate.instant('card.types.BOOK');
                    } else if (currentFilter.code == LibraryApplicationData.constants.LEARNING_PLAN) {
                        currentFilter.icon = "book"
                        currentFilter.subIconType = $translate.instant('card.types.PLAYLIST');
                    } else if (currentFilter.code == LibraryApplicationData.constants.PROJECT) {
                        currentFilter.icon = "collections-bookmark"
                        currentFilter.subIconType = $translate.instant('card.types.PROJECT');
                    } else if (currentFilter.code == LibraryApplicationData.constants.PODCAST) {
                        currentFilter.icon = "library-music"
                        currentFilter.subIconType = $translate.instant('card.types.PODCAST');
                    } else if (currentFilter.code == LibraryApplicationData.constants.GRAPH) {
                        currentFilter.icon = "featured-play-list"
                        currentFilter.subIconType = $translate.instant('card.types.GRAPH');
                    } else if (currentFilter.code == LibraryApplicationData.constants.DOCUMENT) {
                        currentFilter.icon = "description"
                        currentFilter.subIconType = $translate.instant('card.types.DOCUMENT');
                    } else if (currentFilter.code == LibraryApplicationData.constants.EBOOK) {
                        currentFilter.icon = "phone-android"
                        currentFilter.subIconType = $translate.instant('card.types.EBOOK');
                    } else if (currentFilter.code == LibraryApplicationData.constants.ELEARNING) {
                        currentFilter.icon = "dvr"
                        currentFilter.subIconType = $translate.instant('card.types.ELEARNING');
                    } else if (currentFilter.code == LibraryApplicationData.constants.VIDEO) {
                        currentFilter.icon = "video-library"
                        currentFilter.subIconType = $translate.instant('card.types.VIDEO');
                    } else if (currentFilter.code == LibraryApplicationData.constants.IMAGE) {
                        currentFilter.icon = "insert-photo"
                        currentFilter.subIconType = $translate.instant('card.types.IMAGE');
                    }
                }
            }
        }

        // Verifica un filtro (nella lista per "tipologia") è già stato selezionato
        this.isFilterTypeSelected = (currentFilter: any, selectedFilters: any) => {
            let isSelected = false;
            if (currentFilter && selectedFilters && selectedFilters.length) {
                for (let i = 0, selectedLength = selectedFilters.length; i < selectedLength; i++) {
                    if (currentFilter.code === selectedFilters[i].code) {
                        isSelected = true;
                        break;
                    }
                }
            }

            return isSelected;
        }

        // Dato un elenco di Tag, verifica se possiedono un ordinamento e li ordina di conseguenza
        this.sortResult = (tagList: any[]): void => {
            if (tagList && tagList.length) {
                for (let i = 0, tagLength = tagList.length; i < tagLength; i++) {
                    let currentResult = tagList[i];
                    if (currentResult && currentResult.tagAttributes && currentResult.tagAttributes.length) {
                        for (let k = 0, attributesLength = currentResult.tagAttributes.length; k < attributesLength; k++) {
                            let currentAttribute = currentResult.tagAttributes[k];
                            if (currentAttribute.attributeType == LibraryApplicationData.constants.ORDER) {
                                currentResult.order = currentAttribute.attributeValue;
                                break;
                            }
                        }
                    }
                }

                tagList.sort((a: any, b: any) => {
                    return parseFloat(a.order) - parseFloat(b.order);
                });
            }
        }

        // Dato un elenco di Tag, rimuove quelli già selezionati
        this.removeAlreadySelected = (key: string, fullList: any, selectedFilters?: Tag[]): void => {
            if (fullList && fullList.length && key && key.length && this.tagList && this.tagList[key] && selectedFilters && selectedFilters.length) {
                if (Array.isArray(fullList[0])) {
                    // Rimuovo i selezionati dalla lista già suddivisa per titolo
                    for (let i = 0, arrayContainerLength = fullList.length; i < arrayContainerLength; i++) {
                        let currentContainer = fullList[i];
                        for (let t = 0, selectedTagsLength = selectedFilters.length; t < selectedTagsLength; t++) {
                            for (let j = currentContainer.length - 1; j >= 0; j--) {
                                if (currentContainer[j].tagId && selectedFilters[t].tagId && currentContainer[j].tagId === selectedFilters[t].tagId) {
                                    currentContainer.splice(j, 1);
                                    break;
                                }
                            }
                        }
                    }
                } else {
                    for (let t = 0, selectedTagsLength = selectedFilters.length; t < selectedTagsLength; t++) {
                        for (let j = fullList.length - 1; j >= 0; j--) {
                            if (fullList[j].tagId && selectedFilters[t].tagId && fullList[j].tagId === selectedFilters[t].tagId) {
                                fullList.splice(j, 1);
                                break;
                            }
                        }
                    }
                }
            }
        }

        // Divide un elenco di Tag in lettere. Per ogni set di lettere, costruisco un contenitore filtrando tra i risultati della ricerca. Alla fine, dunque,
        // otterrò un Array di Array, e ogni Array contenuto nell'Array principale sarà corrispondente ad ogni set di lettere
        this.divideIntoLetters = (tagList: any[], lettersDivision: string, originalFilter: any): void => {
            // Se il primo elemento del tagList è un array, significa che l'ho già suddiviso
            if (tagList && tagList.length && lettersDivision && lettersDivision.length && !Array.isArray(tagList[0])) {
                for (let i = 0, lettersLength = lettersDivision.length; i < lettersLength; i++) {
                    let currentLettersArray = lettersDivision[i];
                    for (let j = 0, tagLength = tagList.length; j < tagLength; j++) {
                        let currentTag = tagList[j];

                        // Verifico se devo aggiungere una classe CSS specifica
                        if (currentTag.tagType === LibraryApplicationData.constants.TECH_COMPETENCES) {
                            currentTag.customCompetenceCSS = 'bg-primary-12';
                        } else if (currentTag.tagType === LibraryApplicationData.constants.SOFT_COMPETENCES) {
                            currentTag.customCompetenceCSS = 'bg-primary-11';
                        }

                        // Eseguo la divisione
                        for (let k = 0, currentLettersArrayLength = currentLettersArray.length; k < currentLettersArrayLength; k++) {
                            if (currentTag.title.toString().indexOf(currentLettersArray[k]) === 0) {
                                if (!originalFilter.list[i]) {
                                    originalFilter.list[i] = [];
                                }
                                originalFilter.list[i].push(currentTag);
                            }
                        }
                    }
                }
            };
        }

        // Pulisce gli elementi della lista di un tag
        this.removeTagList = (tagType:string): void => {
            if(tagType && this.tagList && this.tagList[tagType] && this.tagList[tagType].list && this.tagList[tagType].list.length){
                this.tagList[tagType].list = null;
            }
        }

        // Chiama i servizi per recuperare la lista di tag di una specifica tipologia
        this.searchTags = (tagType: string, searchedText?: string, currentFilter?: any): any => {
            return $q((resolve: Function, reject: Function) => {
                if (this.tagList[tagType].list && this.tagList[tagType].list.length) {
                   // if (tagType === LibraryApplicationData.constants.FUNCTIONAL_AREAS && this.tagList[LibraryApplicationData.constants.FUNCTIONAL_AREAS].list && this.tagList[LibraryApplicationData.constants.FUNCTIONAL_AREAS].list.length) {
                        resolve(this.tagList[tagType].list);
                   // } else {
                        // Se il filtro richiesto non è una competenza (quindi l'unione fra tech e soft) e in precedenza avevo già recuperato i filtri, li torno
                  //      resolve(currentFilter.list);
                    //}
                } else {
                    if (tagType === LibraryApplicationData.constants.STATUS) {
                        resolve(currentFilter.statusList);
                    }
                    else if (tagType === LibraryApplicationData.constants.APPROACH) {
                        resolve(currentFilter.approachList);
                    } else if (tagType === LibraryApplicationData.constants.TYPE) {
                        // Se è un filtro per tipologia, li recupero in locale; altrimenti sono costretto a chiamare i servizi
                        // Contenitore di tutti i tag della tipologia locali
                        let localTypeTags = LibraryApplicationData.getItemTypesList();

                        // Conenitore degli array filtrati
                        let results: Array<any> = [];

                        // Filtro per l'eventuale parametro testuale
                        if ((/^\d+$/.test(searchedText))) {
                            results = searchedText ? localTypeTags.filter(
                                (desc: any) => {
                                    let regex = new RegExp(searchedText, 'gi');
                                    return desc.code.match(regex);
                                }
                            ) : localTypeTags;
                        } else {
                            results = searchedText ? localTypeTags.filter(
                                (desc: any) => {
                                    let lowercaseQuery = angular.lowercase(searchedText);
                                    let regex = new RegExp(lowercaseQuery, 'gi');
                                    return desc.desc.match(regex);
                                }
                            ) : localTypeTags;
                        }
                        resolve(results);
                    } else {
                        searchTagPromise =
                            SearchTagsService.searchTags.query({
                                allData: true,
                                type: tagType
                            });
                        searchTagPromise.$promise
                            .then((data: SenecaResponse<Array<Tag>>) => {
                                if (data.error) {
                                    // Dati non validi, quindi alzo l'errore
                                    toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
                                    reject();
                                } else {
                                    // Torno i risultati della ricerca
                                    resolve(data.response);
                                }
                                // Annullo la promessa
                                searchTagPromise = null;
                            })
                            .catch((error: any) => {
                                // Annullo la promessa
                                searchTagPromise = null;
                                // Non mostro la modale di errore se ho cancellato volutamente la richiesta
                                if (!error || error.config.timeout.$$state.status !== 1) {
                                    // Verifico se è un problema di connettività
                                    let errorMessage: string = null;

                                    // Nuovo oggetto d'errore
                                    let newError: any = {
                                        severity: "danger"
                                    };

                                    if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
                                        // Problema di connettività
                                        errorMessage = $translate.instant("error.generic.NO_SERVER_TITLE");
                                        newError.hideUnknown = true;
                                    } else {
                                        // Messaggio di errore generico
                                        errorMessage = $translate.instant("error.generic.UNKNOWN_ERROR");
                                    }

                                    // Imposto il messaggio
                                    newError.message = errorMessage;

                                    // Lo aggiungo alla lista
                                    let errors: Array<any> = [];
                                    errors.push(newError);

                                    // E apro la modale
                                    $rootScope.$emit("showApplicationModalErrors", errors);
                                }
                                reject();
                            });
                    }
                }
            });
        }
    });
}